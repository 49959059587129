import GeneralService from './GeneralService'
import {Component} from 'react'

const USER_ENDPOINT = "https://api.staging.yewpay.com"
// const USER_ENDPOINT = "http://localhost:5000"
export class UserService extends Component {
  public static lookupFace(body: string, loadingHandler: any) {
    return GeneralService.post(`/user/lookup_face`, body, null, loadingHandler, JSON, USER_ENDPOINT)
  }
  public static lookupFaces(body: string, loadingHandler: any) {
    return GeneralService.post(`/user/lookup_faces`, body, null, loadingHandler, JSON, USER_ENDPOINT)
  }
}